import useResponsive from 'hooks/useResponsive';
import useTranslation from 'hooks/useTranslation';
import React, { useState } from 'react';
import Button from '../utils/Button';
import style from './index.style';
import Icon from '../utils/Icon';
import { useSelector } from 'react-redux';
import Modal from '../utils/Modal';
import { useRouterWithLang } from 'hooks/useRouterWithLang';

export default function Reseller() {
  const { TRANSLATIONS, translationsParameters, locale } = useTranslation();
  const { isMobile } = useResponsive();
  const router = useRouterWithLang();
  const isLoggedIn = useSelector((s) => s.isLoggedIn);
  const [modalOpen, setModalOpen] = useState({ type: null, index: -1 });

  return (
    <div className="reseller">
      <Modal
        openModal={
          modalOpen?.type && (modalOpen?.index >= 0 || modalOpen?.index.length)
        }
        onClose={() => setModalOpen({ type: null, index: -1 })}
        width={isMobile ? '100%' : '750px'}
        scroll
        canClose
      >
        <div>
          <iframe
            width="100%"
            height={isMobile ? '300' : '415'}
            src="https://www.youtube.com/embed/0YuNDD2SMP8?controls=0&rel=0&playsinline=1&modestbranding=0&autoplay=1&enablejsapi=1&origin=https%3A%2F%2Fbdroppy.com&widgetid=3"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; mute"
            allowFullScreen
            mute
          ></iframe>
        </div>
      </Modal>
      <div className="reseller-header relative blue-gradient">
        <div className="reseller-header-wrapper d-f ai-c row">
          <div className="col-md-6 d-f g-30 fd-c">
            <h1 className={'black'}>
              {translationsParameters(TRANSLATIONS.pages.reseller.title, [
                <span className="text-gradient">
                  {TRANSLATIONS.pages.reseller.titleGradient}
                </span>,
              ])}
            </h1>
            <h4 className={'black'}>{TRANSLATIONS.pages.reseller.caption}</h4>
            <p style={{ lineHeight: '24px' }}>
              {translationsParameters(TRANSLATIONS.pages.reseller.subTitle, [
                <span style={{ fontWeight: 'bold', color: '#005eff' }}>
                  {TRANSLATIONS.pages.reseller.subTitleBold}
                </span>,
              ])}
            </p>
          </div>
          <div className="col-md-6">
            <img
              src={TRANSLATIONS.pages.reseller.img_1}
              alt="online-business-bdroppy"
            />
          </div>
        </div>
      </div>
      <div className="reseller-block purple">
        <div className="reseller-block-wrapper d-f fd-c g-30 ta-c">
          <div className={`row d-f ${locale === 'en_US' ? 'jc-sb' : 'jc-c'}`}>
            <h5 className={'white'}>
              {TRANSLATIONS.pages.reseller.titleBox_2}
            </h5>
            <div className={`col-md-6 ${isMobile && 'col-12'}`}>
              <div className="box_black d-f fd-c g-20 ai-c jc-c">
                <div className={'d-f g-10'}>
                  <div className={'d-f fd-c jc-c ai-fs g-10'}>
                    <h5 className={'white'} style={{ textAlign: 'left' }}>
                      {translationsParameters(
                        TRANSLATIONS.pages.reseller.grow,
                        [
                          <span className="text-gradient">
                            {TRANSLATIONS.pages.reseller.sell}
                          </span>,
                        ]
                      )}
                    </h5>
                    <div>
                      <Button
                        className={'action-white large '}
                        label={TRANSLATIONS.pages.reseller.cta_2}
                        onClick={() => {
                          router.push('/dropshipping');
                        }}
                      />
                    </div>
                  </div>
                  {!isMobile && (
                    <img
                      src={TRANSLATIONS.pages.reseller.img_3}
                      alt="online-business-bdroppy"
                      width={180}
                    />
                  )}
                </div>
              </div>
            </div>
            {locale === 'en_US' && (
              <div className={`col-md-6 ${isMobile && 'col-12'}`}>
                <div className="box_black d-f fd-c g-20 ai-c jc-c">
                  <div className={'d-f g-10'}>
                    <div className={'d-f fd-c jc-c ai-fs g-10'}>
                      <h5 className={'white'} style={{ textAlign: 'left' }}>
                        {translationsParameters(
                          TRANSLATIONS.pages.reseller.learnMore,
                          [
                            <span className="text-gradient">
                              {TRANSLATIONS.pages.reseller.learnMoreGradient}
                            </span>,
                          ]
                        )}
                      </h5>
                      <div>
                        <Button
                          className={'action-white large '}
                          label={TRANSLATIONS.pages.reseller.cta_1}
                          onClick={() => {
                            router.push('/become-distributor-duca-di-morrone');
                          }}
                        />
                      </div>
                    </div>
                    {!isMobile && (
                      <img
                        src="/images/home/banner-duca.png"
                        alt="online-business-bdroppy"
                        width={180}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="reseller-block blue-gradient">
        <div className="reseller-block-wrapper d-f ai-c row">
          <div className="col-md-6">
            <img
              src={TRANSLATIONS.pages.reseller.img_4}
              alt="dropshipping-bdroppy"
            />
          </div>
          <div className="col-md-6 d-f g-30 fd-c">
            <h2>{TRANSLATIONS.pages.reseller.everythingYouNeed}</h2>
            <p className={'black'} style={{ lineHeight: '24px' }}>
              {TRANSLATIONS.pages.reseller.manage}
            </p>
            <div>
              <Button
                className={'primary large'}
                label={TRANSLATIONS.pages.reseller.start}
                onClick={() => {
                  router.push(isLoggedIn ? '/pricing' : '/register');
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="reseller-block dark-blue">
        <div className="reseller-block-wrapper d-f fd-c g-30 ta-c">
          <h3 className={'white'}>
            {TRANSLATIONS.pages.reseller.sellDesigner}
          </h3>
          <span className={'white'}>
            {TRANSLATIONS.pages.reseller.integrated}
          </span>
          <div className="d-f jc-c g-20 fw-w">
            <div className="integrations d-f fd-c ai-c jc-c">
              <img
                src="/images/channel/logo/logo-shopify.png"
                alt="logo-shopify"
              />
            </div>
            <div className="integrations d-f fd-c ai-c jc-c">
              <img
                src="/images/channel/logo/logo-prestashop.png"
                alt="logo-prestashop"
              />
            </div>
            <div className="integrations d-f fd-c ai-c jc-c">
              <img
                src="/images/channel/logo/logo-woocommerce.png"
                alt="logo-woocommerce"
              />
            </div>
            <div className="integrations d-f fd-c ai-c jc-c">
              <img src="/images/channel/logo/logo-ecwid.png" alt="logo-ecwid" />
            </div>
            <div className="integrations d-f fd-c ai-c jc-c">
              <img src="/images/channel/logo/logo-wix.png" alt="logo-wix" />
            </div>
            <div className="integrations d-f fd-c ai-c jc-c">
              <img src="/images/channel/logo/logo-ekm.png" alt="logo-ekm" />
            </div>
          </div>
        </div>
      </div>
      <div className="reseller-block">
        <div className="reseller-block-wrapper d-f g-10 jc-c ai-c row">
          <div className="col-md-5 box-blue blue-gradient d-f g-30 fd-c">
            <ul>
              <li>
                <Icon
                  type={'check-circle'}
                  style={{ color: 'var(--accent)' }}
                />
                {TRANSLATIONS.pages.reseller.li_1}
              </li>
              <li>
                <Icon
                  type={'check-circle'}
                  style={{ color: 'var(--accent)' }}
                />
                {TRANSLATIONS.pages.reseller.li_2}
              </li>
              <li>
                <Icon
                  type={'check-circle'}
                  style={{ color: 'var(--accent)' }}
                />
                {TRANSLATIONS.pages.reseller.li_3}
              </li>
              <li>
                <Icon
                  type={'check-circle'}
                  style={{ color: 'var(--accent)' }}
                />
                {TRANSLATIONS.pages.reseller.li_4}
              </li>
            </ul>
          </div>
          <div className="col-md-5 box-blue blue-gradient d-f g-30 fd-c">
            <ul>
              <li>
                <Icon
                  type={'check-circle'}
                  style={{ color: 'var(--accent)' }}
                />
                {TRANSLATIONS.pages.reseller.li_5}
              </li>
              <li>
                <Icon
                  type={'check-circle'}
                  style={{ color: 'var(--accent)' }}
                />
                {TRANSLATIONS.pages.reseller.li_6}
              </li>
              <li>
                <Icon
                  type={'check-circle'}
                  style={{ color: 'var(--accent)' }}
                />
                {TRANSLATIONS.pages.reseller.li_7}
              </li>
              <li>
                <Icon
                  type={'check-circle'}
                  style={{ color: 'var(--accent)' }}
                />
                {TRANSLATIONS.pages.reseller.li_8}
              </li>
            </ul>
          </div>
          <div className={'d-f fd-c jc-c ai-c box-blue-img'}>
            {!isMobile && (
              <img
                src={TRANSLATIONS.pages.reseller.img_5}
                alt="bdroppy"
                width={500}
              />
            )}
          </div>
          <div className={'d-f jc-c'}>
            <Button
              className={'large primary'}
              label={TRANSLATIONS.pages.reseller.findOutMore}
              onClick={() => {
                router.push('/integrations');
              }}
            />
          </div>
        </div>
      </div>
      <div className="reseller-block blue-gradient">
        <div className="reseller-block-wrapper d-f ai-c row">
          <div className="col-md-6">
            <img
              src={TRANSLATIONS.pages.reseller.img_6}
              alt="dropshipping-bdroppy"
            />
          </div>
          <div className={`col-md-6 d-f g-30 fd-c ${isMobile && 'mt-xl'}`}>
            <h2>
              {translationsParameters(TRANSLATIONS.pages.reseller.tech, [
                <span className="text-gradient">
                  {TRANSLATIONS.pages.reseller.techGradient}
                </span>,
              ])}
            </h2>
            <p className={'black'} style={{ lineHeight: '24px' }}>
              {TRANSLATIONS.pages.reseller.platform}
            </p>
            <span className={'black'}>
              {TRANSLATIONS.pages.reseller.thisIsBdroppy}
            </span>
          </div>
        </div>
      </div>
      <div className="reseller-block purple">
        <div className="reseller-block-wrapper d-f ai-c row">
          <div className="col-md-6 d-f g-30 fd-c">
            <h2 className={'white'}>
              {translationsParameters(TRANSLATIONS.pages.reseller.logistic, [
                <span className="text-gradient">
                  {TRANSLATIONS.pages.reseller.logisticGradient}
                </span>,
              ])}
            </h2>
            <p className={'white'} style={{ lineHeight: '24px' }}>
              {TRANSLATIONS.pages.reseller.logisticParagraph}
            </p>
          </div>
          <div
            className="col-md-6"
            style={{ margin: 'auto', textAlign: 'center' }}
          >
            <div className={'relative'}>
              <img
                src={TRANSLATIONS.pages.reseller.img_7}
                alt="dropshipping-bdroppy"
                width={400}
              />
              <div className={'play-btn d-f ai-c jc-c'}>
                <Icon
                  type={'play'}
                  style={{
                    fontSize: 35,
                    color: 'white',
                    marginLeft: '5px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setModalOpen({ type: 'modal', index: 1 })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="reseller-block blue-gradient">
        <div className="reseller-block-wrapper d-f ai-c ta-c row">
          <h2>
            {translationsParameters(TRANSLATIONS.pages.reseller.international, [
              <span className="text-gradient">
                {TRANSLATIONS.pages.reseller.internationalGradient}
              </span>,
            ])}
          </h2>
          <p className={'black'}>
            {TRANSLATIONS.pages.reseller.internationalParagraph}
          </p>
          <div className={'mt-xxl mb-xxl'}>
            <img
              src={isMobile ? './mobile_flags.png' : './flags.png'}
              alt={'flags'}
            />
          </div>
          <p className={'black'} style={{ fontSize: 14, maxWidth: 600 }}>
            {TRANSLATIONS.pages.reseller.countriesList}
          </p>
        </div>
      </div>
      <style jsx>{style}</style>
    </div>
  );
}
